import { render, staticRenderFns } from "./SuReviews.vue?vue&type=template&id=bfe36556&scoped=true"
import script from "./SuReviews.vue?vue&type=script&lang=js"
export * from "./SuReviews.vue?vue&type=script&lang=js"
import style0 from "./SuReviews.vue?vue&type=style&index=0&id=bfe36556&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfe36556",
  null
  
)

export default component.exports